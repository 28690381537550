import React from "react";
import { Link } from "gatsby";

import MainLayout from "../layouts/MainLayout";
import SEO from "../components/common/SEO";
// import Custom Components
import PageHeader from "../components/common/page-header";

function FAQ() {
  return (
    <MainLayout>
      <div className="main">
      
      <SEO title="404 Page" />

        <h1 className="d-none">Jimenez Store Mr. Discount - 404</h1>

        <PageHeader title="404" />

        <img style={{position:'absolute',top:'55%'}} src="/assets/images/404error.jpg" alt="404" />
        <div
          className="error-content text-center"
          // style={{
          //   backgroundImage: `url(assets/images/backgrounds/error-bg.jpg)`,
          // }}
        >
          <div className="container">
            <h1 className="error-title">Error 404</h1>


            <p style={{zIndex:100}}>We are sorry, the page you've requested is not available.</p>
            <Link to="/" className="btn btn-outline-primary-2 btn-minwidth-lg">
              <span>BACK TO HOMEPAGE</span>
              <i className="icon-long-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default React.memo(FAQ);
